import { MapPopupProperties } from 'types';

/**
 * Omits the geometry property from a features properties, as it should
 * not be displayed in a popup with other feature information
 */
export const getDisplayedInfoWithoutGeometry = (properties: MapPopupProperties) => {
  const info: MapPopupProperties = {};

  Object.keys(properties).forEach((key) => {
    if (key !== 'geometry') {
      info[key] = properties[key];
    }
  });

  return info;
};

/**
 * Return the feature's geometry as an object if it exists, null otherwise
 */
export const getGeometryFromSelectedLayer = (layerProperties: MapPopupProperties) => {
  const geometryString = layerProperties.geometry as string;

  return geometryString ? JSON.parse(geometryString) : null;
};

import { FLAG_OVERRIDE_KEY } from 'config/constants';
import { FeatureFlagValue } from 'types';

/**
 * Returns the value from localstorage of an overriden feature flag
 */
export const getLocalFlagOverride = (flagKey: string): FeatureFlagValue => {
  const overridenValues = JSON.parse(localStorage.getItem(FLAG_OVERRIDE_KEY));
  const isEvalutionOverriden =
    overridenValues !== null && Object.prototype.hasOwnProperty.call(overridenValues, flagKey);

  const overridenValue = isEvalutionOverriden ? overridenValues[flagKey] : null;

  return overridenValue;
};

/** Returns the overriden value of a numeric feature flag if it is available and valid,
 * null otherwise
 * */
export const getLocalNumberFlagOverride = (flagKey: string) => {
  const overridenValue = getLocalFlagOverride(flagKey);
  const isValidOverride = overridenValue !== null && typeof overridenValue === 'number';

  return isValidOverride ? overridenValue : null;
};

/** Returns the overriden value of a boolean feature flag if it is available and valid,
 * null otherwise
 * */
export const getLocalBooleanFlagOverride = (flagKey: string) => {
  const overridenValue = getLocalFlagOverride(flagKey);
  const isValidOverride = overridenValue !== null && typeof overridenValue === 'boolean';

  return isValidOverride ? overridenValue : null;
};

/** Returns the overriden value of a string feature flag if it is available and valid,
 * null otherwise
 * */
export const getLocalStringFlagOverride = (flagKey: string) => {
  const overridenValue = getLocalFlagOverride(flagKey);
  const isValidOverride = overridenValue !== null && typeof overridenValue === 'string';

  return isValidOverride ? overridenValue : null;
};
